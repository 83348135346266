export const recommenderIdentification = {
  'Exclusive offer!': 'Oferta exclusiva!',
  'Finish and secure my discount': 'Finalizar e garantir meu desconto',
  'Make my register': 'Realizar meu cadastro',
  'Register to guarantee your benefit at Vialaser':
    'Faça seu cadastro para garantir seu beneficio na Vialaser',
  'Registered successfully!': 'Registrado com sucesso!',
  'The informed e-mail is not valid!': 'O e-mail informado está inválido!',
  'You must inform the e-mail!': 'Voce precisa informar o e-mail!',
  'Your e-mail': 'Seu e-mail',
  'Your name and lastname': 'Seu nome e sobrenome',
};
