import React from 'react';

import { Redirect, Route as StandardRoute } from 'react-router-dom';

import { TLanguage } from 'config/tenancy';
import { useLocale } from 'shared/presentation/contexts/LocaleContext';

import { TRoutePaths } from '../../models';

interface IRouteProps {
  path: TRoutePaths;
  exact?: boolean;
  component: React.ComponentType<any>;
  disabledTenancies?: TLanguage[];
}

const Route: React.FC<IRouteProps> = ({ path, disabledTenancies, ...rest }) => {
  const { language } = useLocale();

  if (disabledTenancies?.includes(language)) return <Redirect to="/" />;

  return <StandardRoute path={path} {...rest} />;
};

export default Route;
