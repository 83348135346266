import React, { ComponentProps, useMemo } from 'react';

import { useAppContext } from 'contexts/AppContext';
import { useCartContext } from 'contexts/NewCartContext';

import {
  BlackNovemberCountdown,
  NavbarMenuIcon,
  NavigationMenu,
  VialaserLogo,
} from 'shared/presentation/components/atoms';
import {
  PackageSearchBox,
  ProfileHoverCard,
} from 'shared/presentation/components/molecules';
import { CONTACTS, COUNTRY_CODES, ICONS } from 'shared/presentation/constants';
import { Link, useLocale, useTheme } from 'shared/presentation/contexts';
import { buildWhatsappLink } from 'shared/presentation/helpers';
import { useEventTracker } from 'shared/presentation/hooks';

import * as S from './styles';

const DesktopNavbar: React.FC = () => {
  const { theme } = useTheme();
  const { t, language } = useLocale('shared');
  const tracker = useEventTracker();
  const cart = useCartContext();
  const { globalState } = useAppContext();

  const customerName = useMemo(() => {
    if (!globalState.token) return null;

    return localStorage.getItem('nome') as string | null;
  }, [globalState.token]);

  const icons: ComponentProps<typeof NavbarMenuIcon>[] = [
    {
      Icon: ICONS.WHATSAPP,
      type: 'external',
      title: 'Whatsapp',
      to: buildWhatsappLink({
        countryCode: COUNTRY_CODES[language],
        number: CONTACTS[language],
        language,
        message: t(
          "I'm interested in knowing more about laser hair removal promotions",
        ),
      }),
      onClick: () => tracker.register('headerWhatsappButtonClick'),
    },
    {
      Icon: ICONS.CART,
      type: 'internal',
      title: t('Cart'),
      to: '/carrinho',
      badge: cart.count > 0 ? `${cart.count}` : undefined,
    },
    {
      Icon: ICONS.CALENDAR,
      type: 'internal',
      title: t('Schedule session'),
      to: '/agendamento',
    },
  ];

  return (
    <S.Container>
      <S.TopMenu>
        <Link to="/">
          <VialaserLogo
            color={theme.palette.text.main}
            colors={{ via: theme.palette.primary.main }}
            size={190}
          />
        </Link>

        <PackageSearchBox />

        <S.IconMenu>
          {icons.map(icon => (
            <NavbarMenuIcon key={icon.title} {...icon} />
          ))}
        </S.IconMenu>
      </S.TopMenu>
      <BlackNovemberCountdown maxWidth={180} />

      <S.BottomMenu>
        <NavigationMenu
          items={[
            {
              title: t('Buy now'),
              to: '/pacotes',
              content: t('Buy now'),
              type: 'default',
            },
            {
              title: t('Who we are'),
              to: '/quem-somos',
              content: t('Who we are'),
              type: 'default',
            },
            {
              title: t('Our technology'),
              to: '/nossa-tecnologia',
              content: t('Our technology'),
              type: 'default',
            },
            {
              title: t('Blog'),
              to: 'https://vialaser.com.br/blog',
              content: t('Blog'),
              type: 'external',
            },
            {
              title: t('Find a clinic'),
              to: '/#where-to-find',
              content: t('Find a clinic'),
              type: 'hash',
            },
          ]}
        />

        {customerName ? (
          <ProfileHoverCard customerName={customerName} />
        ) : (
          <NavigationMenu
            items={[
              {
                title: t('Create your account'),
                to: '/login',
                content: t('Create your account'),
                type: 'default',
              },
              {
                title: t('Enter'),
                to: '/login',
                content: t('Enter'),
                type: 'default',
              },
            ]}
          />
        )}
      </S.BottomMenu>
    </S.Container>
  );
};

export default DesktopNavbar;
