export const recommenderIdentification = {
  'Exclusive offer!': '¡Oferta exclusiva!',
  'Finish and secure my discount': 'Finalizar y asegurar mi descuento',
  'Make my register': 'Completar mi registro',
  'Register to guarantee your benefit at Vialaser':
    'Registra tu cuenta para asegurar tu beneficio en Vialaser',
  'Registered successfully!': '¡Registrado con éxito!',
  'The informed e-mail is not valid!':
    '¡El correo electrónico ingresado no es válido!',
  'You must inform the e-mail!': '¡Necesitas informar el correo electrónico!',
  'Your e-mail': 'Su email',
  'Your name and lastname': 'Tu nombre y apellido',
};
