import { useCallback, useMemo } from 'react';

import { useAppContext } from 'contexts/AppContext';

import IEventTrackerProvider from 'shared/domain/providers/IEventTrackerProvider';
import { makeEventTrackerProvider } from 'shared/infra/providers';
import { useRoute } from 'shared/presentation/contexts';

type TRegisterParams = Parameters<IEventTrackerProvider['register']>;
type TParams = { userId?: number };

export default function useEventTracker(params: TParams = {}) {
  const eventTracker = useMemo(() => makeEventTrackerProvider(), []);
  const route = useRoute();
  const app: any = useAppContext();

  const register = useCallback(
    (event: TRegisterParams['0'], payload?: TRegisterParams['2']) => {
      eventTracker.register(
        event,
        {
          device: navigator.userAgent,
          route: route.pathname,
          userId: params.userId || app.state?.user?.id,
        },
        payload,
      );
    },
    [eventTracker, route, app.state?.user?.id, params.userId],
  );

  return { register };
}
