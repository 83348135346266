import { useCallback, useMemo, useState } from 'react';

import {
  TStorageBody,
  TStorageKey,
} from 'shared/domain/providers/IStorageProvider';
import { makeStorageProvider } from 'shared/infra/providers';

type TOptions<T extends TStorageKey> = {
  defaultValue: TStorageBody<T>;
  type?: NonNullable<Parameters<typeof makeStorageProvider>[0]>['type'];
};

export default function useStorageState<T extends TStorageKey>(
  key: T,
  options: TOptions<T>,
) {
  const storage = useMemo(
    () => makeStorageProvider({ type: options.type }),
    [options.type],
  );

  const [value, setValue] = useState(storage.get(key) || options.defaultValue);

  const setState = useCallback(
    (newState: TStorageBody<T>) => {
      setValue(newState);
      storage.store(key, newState);
    },
    [key, storage],
  );

  return [value, setState] as const;
}
