import {
  AcceptedPaymentMethods,
  AllRightsFooter,
  AppStoreIcons,
  BlipChatBubble,
  FooterBottomLinks,
  FooterContact,
  RdStationScript,
  VialaserLogo,
  VialaserSocials,
} from 'shared/presentation/components/atoms';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const LOGO_SIZE = 172;

const StoreFooter: React.FC = () => {
  const { theme } = useTheme();

  return (
    <>
      <S.Container>
        <div className="info">
          <div>
            <VialaserLogo
              size={LOGO_SIZE}
              color={theme.palette.text.main}
              colors={{ via: theme.palette.primary.main }}
            />
            <FooterBottomLinks />
            <AcceptedPaymentMethods />
          </div>

          <div>
            <AppStoreIcons />
            <FooterContact />
            <VialaserSocials />
          </div>
        </div>

        <AllRightsFooter />
      </S.Container>

      <RdStationScript />
      <BlipChatBubble />
    </>
  );
};

export default StoreFooter;
