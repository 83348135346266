import { useMemo } from 'react';

import { Helmet } from 'react-helmet';

import { useLocale } from 'shared/presentation/contexts';

interface IDocumentHeadProps {
  title: string;
  article?: {
    title: string;
    description: string;
  };
}

const DocumentHead: React.FC<IDocumentHeadProps> = ({ title, article }) => {
  const { language } = useLocale();

  const meta = useMemo(() => {
    if (!article) return undefined;

    return [
      {
        property: 'og:type',
        content: 'article',
      },
      {
        property: 'og:url',
        content: window.location.href,
      },
      {
        property: 'og:image',
        content: '%PUBLIC_URL%/vialaser-logo.png',
      },
      {
        property: 'og:title',
        content: article.title,
      },
      {
        property: 'og:description',
        content: article.description,
      },
    ];
  }, [article]);

  return (
    <Helmet defaultTitle="Vialaser" titleTemplate="%s | Vialaser" meta={meta}>
      <html lang={language} />

      <title>{title}</title>
    </Helmet>
  );
};

export default DocumentHead;
