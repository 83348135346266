import IEventTrackerProvider from 'shared/domain/providers/IEventTrackerProvider';
import makeHTTPProvider from 'shared/infra/providers/makeHTTPProvider';
import makeStorageProvider from 'shared/infra/providers/makeStorageProvider';

import DataLayerEventTrackerProvider from './implementations/DataLayerEventTrackerProvider';
import LogEventTrackerProvider from './implementations/LogEventTrackerProvider';

type TEventTracker = 'DATA_LAYER' | 'LOG';

const instances = new Map<TEventTracker, IEventTrackerProvider>();

const FACTORIES: Record<TEventTracker, () => IEventTrackerProvider> = {
  LOG: () => new LogEventTrackerProvider(),
  DATA_LAYER: () => {
    const httpProvider = makeHTTPProvider({
      source: 'METRICS',
    });
    const storageProvider = makeStorageProvider();

    return new DataLayerEventTrackerProvider(httpProvider, storageProvider);
  },
};

export default function makeEventTrackerProvider(): IEventTrackerProvider {
  const type: TEventTracker =
    process.env.NODE_ENV === 'production' ? 'DATA_LAYER' : 'LOG';

  if (!instances.has(type)) {
    const factory = FACTORIES[type];

    instances.set(type, factory());
  }

  return instances.get(type)!;
}
