export const threeSessionsGift = {
  'Adquire now!': '¡Adquiéralo ahora!',
  Afternoon: 'Tardecita',
  'Avoid solar exposition for 15 days before the sessions for areas that are largely exposed to the sun and 5 days before for less exposed ones':
    'Evite la exposición al sol 15 días antes de la sesión para áreas que estén muy expuestas al sol y 5 días antes para las áreas que estén menos expuestas.',
  'Choose another schedule': 'Elegir otro momento',
  'Choose the area to discover how much Vialaser can change your life with laser hair removal':
    'Escoja el área para conocer cuanto el Vialaser puede cambiar su vida con la depilación láser.',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  Close: 'Cerrar',
  'Cut the hairs with a blade or hair removal cream 2 days before the session Its important that only the tip of the hair is exposed':
    'Recortar el vello con un rasurador o una crema depilatoria uno o dos días antes de la sesión. Es importante que solo quede expuesta la punta del vello.',
  'Day {{date}}': 'Día {{date}}',
  Email: 'Correo electrónico',
  'Enjoy 3 hair removal sessions, totally free!':
    '¡Aprovecha 3 sesiones de depilación a láser, totalmente gratis!',
  'Full name': 'Nombre completo',
  'Get my free sessions': 'Ganar mis sesiones gratis',
  'Hello {{name}}, choose the area to earn your three free sessions!':
    '¡Hola {{name}}, escoja el area para ganar tus tres sesiones gratis!',
  'Hello {{name}}! Choose the best day for your session':
    '¡Hola {{name}! Elige el mejor día para hacer tu sesión',
  'Hello {{name}}, select the unit to schedule your three free sessions!':
    '¡Hola {{name}}, selecciona la unidad para programar tus tres sesiones gratis!',
  'I accept the <1>Terms and conditions</1>, I authorize the use of my data according to the <3>Privacy policy</3> and even receive news and promotions from Vialaser':
    'Acepto los <1>Términos y condiciones</1>, autorizo el uso de mis datos de acuerdo con la <3>Declaracion de privacidad</3> además de recibir promociones y novedades de Vialaser.',
  Morning: 'Mañana',
  'Next week': 'Próxima semana',
  Night: 'Noche',
  Phone: 'Teléfono',
  'Previous week': 'Semana previa',
  Recommendations: 'Recomendaciones',
  'Schedule my free sessions': 'Programar mis sesiones gratis',
  'Schedule my session now': 'Programar mi sesión ahora',
  'Schedule now!': '¡Programar ahora!',
  'Searching the available times': 'Buscando las horas disponibles...',
  'Select or search for the closest unit':
    'Selecciona o busca la unidad o sucursal más próxima',
  'Select schedule': 'Seleccionar hora',
  'Select your schedule': 'Seleccione su horario',
  'Session scheduled!': '¡Sesión programada!',
  'To start, inform your email!':
    '¡Para empezar, por favor, informá tu correo electrónico!',
  "Yahoo! I've entered the You Won page and I want to schedule my 3 free sessions!":
    '¡Uhuu! ¡Entré en la página Has Ganado 3 Sesiones y quiero agendarlas!',
  'You have earned three sessions': 'Has ganado tres sesiones',
  'You have already earned your bonus': 'Ya tienes asegurado tu bono',
  "We're almost there! Now that you've chosen the unit nearest from you, it's not long until you get your three free sessions":
    '¡Estamos casi alli! Ahora que has elegido la clínica más cercana a ti, ya queda poco para garantizar tus 3 sesiones gratuitas.',
  '{{weekday}}, {{date}} at {{time}}': '{{weekday}}, {{date}} a las {{time}}',

  Sunday: 'Domingo',
  Monday: 'Lunes',
  Tuesday: 'Martes',
  Wednesday: 'Miércoles',
  Thursday: 'Jueves',
  Friday: 'Viernes ',
  Saturday: 'Sábado',
};
