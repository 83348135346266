export const threeSessionsGift = {
  'Adquire now!': 'Garanta já!',
  Afternoon: 'Tarde',
  'Avoid solar exposition for 15 days before the sessions for areas that are largely exposed to the sun and 5 days before for less exposed ones':
    'Evitar exposição solar de 15 dias antes da sessão para áreas muito expostas ao sol e 5 dias antes para áreas menos expostas.',
  'Choose another schedule': 'Escolher outro horário',
  'Choose the area to discover how much Vialaser can change your life with laser hair removal':
    'Escolha a área para conhecer quanto a Vialaser pode mudar a sua vida com depilação a laser.',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  Close: 'Fechar',
  'Cut the hairs with a blade or hair removal cream 2 days before the session Its important that only the tip of the hair is exposed':
    'Aparar os pelos com lâmina ou creme depilatório 2 dias antes da sessão. É importante que apenas a ponta do pelo esteja exposta.',
  'Day {{date}}': 'Dia {{date}}',
  Email: 'E-mail',
  'Enjoy 3 hair removal sessions, totally free!':
    'Aproveite 3 sessões de depilação a laser, totalmente grátis!',
  'Full name': 'Nome completo',
  'Get my free sessions': 'Ganhar minhas sessões grátis',
  'Hello {{name}}, choose the area to earn your three free sessions!':
    'Olá {{name}}, escolha a área para ganhar suas três sessões grátis!',
  'Hello {{name}}! Choose the best day for your session':
    'Olá {{name}}! Escolha o melhor dia para fazer sua sessão',
  'Hello {{name}}, select the unit to schedule your three free sessions!':
    'Olá {{name}}, selecione a unidade para agendar suas três sessões grátis!',
  'I accept the <1>Terms and conditions</1>, I authorize the use of my data according to the <3>Privacy policy</3> and even receive news and promotions from Vialaser':
    'Aceito os <1>Termos e condições</1>, autorizo o uso de meus dados de acordo com a <3>Declaração de privacidade</3> e ainda receber promoções e novidades da Vialaser.',
  Morning: 'Manhã',
  'Next week': 'Próxima semana',
  Night: 'Noite',
  Phone: 'Telefone',
  'Previous week': 'Semana anterior',
  Recommendations: 'Recomendações',
  'Schedule my free sessions': 'Agendar minhas sessões grátis',
  'Schedule my session now': 'Agendar minha sessão agora',
  'Schedule now!': 'Agende já!',
  'Searching the available times': 'Buscando os horários disponíveis...',
  'Select or search for the closest unit':
    'Selecione ou pesquise a unidade mais próxima',
  'Select schedule': 'Selecionar horário',
  'Select your schedule': 'Selecione seu horário',
  'Session scheduled!': 'Sessão agendada!',
  'To start, inform your email!': 'Para começar, informe o seu e-mail!',
  "Yahoo! I've entered the You Won page and I want to schedule my 3 free sessions!":
    'Uhuu! Entrei na página Você Ganhou e quero agendar minhas 3 sessões grátis!',
  'You have earned three sessions': 'Você ganhou três sessões',
  'You have already earned your bonus': 'Você já garantiu sua bonificação',
  "We're almost there! Now that you've chosen the unit nearest from you, it's not long until you get your three free sessions":
    'Estamos quase lá! Agora que já escolheu a clínica mais próxima a você, falta pouco para garantir suas 3 sessões grátis.',
  '{{weekday}}, {{date}} at {{time}}': '{{weekday}}, {{date}} às {{time}}',

  Sunday: 'Domingo',
  Monday: 'Segunda-feira',
  Tuesday: 'Terça-feira',
  Wednesday: 'Quarta-feira',
  Thursday: 'Quinta-feira',
  Friday: 'Sexta-feira',
  Saturday: 'Sábado',
};
